import React from 'react'
import { Modal } from 'react-bootstrap';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: 'white',
    },
    section: {
        margin: 10,
        padding: 10,
        // flexGrow: 1
    },
    headingSection: {
        display: "flex",
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: '#bfbfbf',
        margin: '10px 0',
    },
    tableRow: {
        flexDirection: "row",
        flexWrap: "nowrap", // Ensures that the row content doesn't wrapal

    },
    tableColHeader: {
        width: "12.5%",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: '#bfbfbf',
        backgroundColor: "#e0e0e0",
        padding: 5,
    },
    tableCol: {
        width: "12.5%",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: '#bfbfbf',
        padding: 5,
    },
    tableCell: {
        margin: "auto",
        fontSize: 9,
        textAlign: "left",
        // Ensures content is centered
    },
    tableColSpan: {
        width: "98%", // Span across 6 columns (6 * 12.5%)
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: '#bfbfbf',
        padding: 5,
        textAlign: "center",
    },
    totalCell: {
        fontSize: 10,
        fontWeight: "extrabold",
        marginRight: 10,
        textAlign: "center", 
        // width: "10%", 
    }

});

export default function ReceiveReportPdf(props) {
    const { pdfModal, setPdfModal, startDate, setStartDate, reportData, setReportData } = props;
    const handleClose = () => {
        { setPdfModal(false);  }
    }
    // console.log("startdDate", startdDate)

    const totalAmt = reportData?.reduce((acc, item) => acc + item.mtotal_amt, 0);

    const MyDocument = () => (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <View style={styles.headingSection}>
                        <Text>Material Receive Report</Text>
                        <Text>Date : {startDate}</Text>
                    </View>
                    <View style={styles.table}>
                        {/* Table Header */}
                        <View style={styles.tableRow}>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCell}>SKF ITEM NAME</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCell}>OPIL ITEM NAME</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCell}>OPIL ITEM CODE</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCell}>RECEIVE DATE</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCell}>RECEIVE  QTY.</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCell}>BASIC RATE</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCell}>DISCOUNT</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCell}>RATE</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCell}>TOTAL VALUE</Text>
                            </View>
                        </View>
                        {/* Table Data */}
                        {reportData?.map((item, index) => (
                            <View style={styles.tableRow} key={index}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{item.vitem_description}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{item.vitem_name}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{item.vdrawing_code}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{item.vreceived_date}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{item.fquantity}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{item.mmrp_rate.toFixed(2)}</Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{item.mmrp_discount}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{item.mrate.toFixed(2)}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{item.mtotal_amt.toFixed(2)}</Text>
                                </View>
                            </View>
                        ))}
                        <View style={styles.tableRow}>
                            <View style={styles.tableColSpan}>
                                <Text style={styles.totalCell}>Total</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{totalAmt.toFixed(2)}</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );

    return (
        <>
            <Modal show={pdfModal} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>PDF GENRATE</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>

                        <PDFViewer width="100%" height="600">
                            <MyDocument />
                        </PDFViewer>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

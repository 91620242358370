import React, { useEffect, useState } from 'react'
import DataTable from '../../../component/data_table/DataTable'
import { Button, Table } from 'react-bootstrap'
import { LuPrinter } from 'react-icons/lu';
import { useReactToPrint } from 'react-to-print'
import Flatpickr from 'react-flatpickr'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import { FaRegFileExcel } from 'react-icons/fa6';
import { Form } from 'react-bootstrap'
// import { exceluploadRes, exceluploadTodo } from '../../redux/slices/excelFileUpload';
import xlsx from "json-as-xlsx"
import "flatpickr/dist/themes/light.css"
import moment from 'moment';
import Loader from '../../../component/loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { IssueReportListRes, IssueReportListTodo } from '../../../redux/slices/store/report-list/IssueReportList';
import IssuedReportPdf from './issuedReportPdf';

export default function IssuedReportList() {
    const handlePrint = useReactToPrint({
        content: () => document.getElementById('pdf-content'),
    });
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const issueReportList = useSelector(state => state?.IssueReportList?.data?.data)

    const [pdfModal, setPdfModal] = useState(false)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [reportData, setReportData] = useState([])

    const handlePdf = () => {
        setPdfModal(true)
        setReportData(issueReportList)
    }


    const handleDate = (data) => {
        setLoading(true)
        dispatch(IssueReportListTodo({ startDate: data[0], endDate: data[1] })).then(() => report_res())
    }

    const totalAmt = issueReportList?.reduce((acc, item) => acc + item.mtotal_amt, 0);

    const report_res = () => {
        if (IssueReportListRes?.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    useEffect(() => {
        setLoading(true)
        dispatch(IssueReportListTodo({ startDate: '00/00/0000', endDate: '00/00/0000' })).then(() => report_res()); // Reset the date field

    }, []);

    const ReceiveReportData = []

    const ReceiveReportDataRender = issueReportList && issueReportList.map((value, index) => ReceiveReportData.push({
        "Sn": index + 1,
        "skf_item_name": value.vitem_description,
        "opil_item_name": value.vitem_name,
        "opil_item_code": value.vdrawing_code,
        "dissued_date": value.dissued_date,
        "quantity": value.mstock,
        "mmrp_rate": value.mmrp_rate.toFixed(2),
        "mmrp_discount": value.mmrp_discount.toFixed(2),
        "mrate": value.mrate.toFixed(2),
        "mtotal_amt": value.mtotal_amt.toFixed(2),
        // "remark": value.cost,
    }))

    const downloadFile = () => {
        const totalAmt = issueReportList?.reduce((acc, item) => acc + item.mtotal_amt, 0);
        let exceldata = [
            {
                columns: [
                    { label: "S.NO.", value: "Sn" },
                    { label: "SKF ITEM NAME", value: "skf_item_name" },
                    { label: "OPIL ITEM NAME", value: "opil_item_name" },
                    { label: "OPIL ITEM CODE ", value: "opil_item_code" },
                    { label: "ISSUED DATE ", value: "dissued_date" },
                    { label: "ISSUED QUANTITY", value: "quantity" },
                    { label: "BASIC RATE", value: "mmrp_rate" },
                    { label: "DISCOUNT", value: "mmrp_discount" },
                    { label: "RATE", value: "mrate" },
                    { label: "TOTAL VALUE", value: "mtotal_amt" },
                ],
                content:
                    [
                        ...ReceiveReportData,
                        {
                            Sn: '',
                            skf_item_name: '',
                            opil_item_name: '',
                            opil_item_code: '',
                            dissued_date: '',
                            quantity: '',
                            mmrp_rate: '',
                            mmrp_discount: '',
                            mrate: 'Total',
                            mtotal_amt: totalAmt.toFixed(2),

                        }]
            },

        ]
        let settings = {
            fileName: "ExcelSheet1",
        }
        xlsx(exceldata, settings)

    }
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="d-flex justify-content-between align-items-center layout_head">
                            <div className='d-flex justify-content-start align-items-center'>
                                <div>
                                    <h5>Issued   Report List</h5>
                                </div>
                                <div className='ms-3 mb-2'>
                                    {/* <Flatpickr
                                        className='form-control form_input flatePicker'
                                        placeholder={'Date Filter'}
                                        option={{
                                            altInput: true,
                                            altFormat: "F j, Y",
                                            dateFormat: 'Y-m-d',
                                            minDate: new Date().toISOString().split("T")[0],
                                            maxDate: '',
                                            disable: [],
                                            mode: "single",
                                            defaultDate: [],
                                            conjunction: "",
                                            static: true,

                                        }}
                                        name='deadline'
                                        // value={values.deadline || ''}
                                        onChange={(e) => {
                                            handleDate(moment(e[0]).format("DD/MM/YYYY"))
                                            setStartDate(moment(e[0]).format("DD/MM/YYYY"))
                                        }}
                                    /> */}

                                    <Flatpickr
                                        className="form-control form_input flatePicker"
                                        placeholder="Date Filter"
                                        style={{ width: '250px' }}
                                        options={{
                                            altInput: false,
                                            altFormat: "F j, Y",
                                            dateFormat: "d-m-Y",
                                            minDate: "", // Set to current date
                                            maxDate: "", // Optional: add a max date
                                            disable: [], // Dates to disable
                                            mode: "range", // Enable range selection
                                            defaultDate: [], // Optional: pre-select a range
                                            conjunction: " to ", // Separator for range
                                            static: false,

                                        }}
                                        onChange={(selectedDates) => {
                                            // Handle the range selection
                                            if (selectedDates.length === 2) {
                                                const formattedRange = selectedDates.map((date) =>
                                                    moment(date).format("DD/MM/YYYY")
                                                );
                                                handleDate(formattedRange);
                                                setStartDate(formattedRange?.[0]);
                                                setEndDate(formattedRange?.[1]);
                                            }
                                        }}
                                    />

                                </div>
                            </div>
                            {issueReportList?.length ? <div className='d-flex align-items-center'>
                                <Button variant='primary' size='sm' onClick={handlePdf}>
                                    Print <LuPrinter />
                                </Button>
                                <Button className='ms-2' variant='primary' size='sm' onClick={() => downloadFile()} >
                                    Excel Download <FaRegFileExcel />
                                </Button>
                            </div> : ""}

                        </div>
                        {/* <div className="col-md-12 mt-1" >
                            <DataTable
                                rowData={issueReportList}
                                columnDefs={columnDefs}
                                height={'75vh'}
                            />

                        </div> */}
                        <div className='col-md-12 mt-3' id='pdf-content' >

                            {/* <PerfectScrollbar style={{ height: '77vh' }}> */}
                            <Table striped bordered hover className=' '>
                                <thead className=''>
                                    <tr>
                                        <th style={{ width: "20px" }}>S.NO</th>
                                        <th>SKF ITEM NAME</th>
                                        <th>OPIL ITEM NAME</th>
                                        <th>OPIL ITEM CODE</th>
                                        <th>ISSUED DATE</th>
                                        <th>ISSUED QTY.</th>
                                        <th>BASIC RATE</th>
                                        <th>DISCOUNT</th>
                                        <th>RATE</th>
                                        <th>TOTAL VALUE</th>

                                    </tr>
                                </thead>
                                {
                                    loading ?
                                        <tbody>
                                            <tr>
                                                <td className='text-center p-5' style={{ fontSize: "18px", color: '#9d9d9d', fontWeight: "500" }} colSpan={10}> <div className='d-flex justify-content-center '>
                                                    <Loader />
                                                </div></td>
                                            </tr>
                                        </tbody>
                                        :
                                        issueReportList?.length ?
                                            <tbody >
                                                {issueReportList?.map((value, index) =>
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{value.vitem_description}</td>
                                                        <td> {value.vitem_name}</td>
                                                        <td > {value.vdrawing_code}</td>
                                                        <td > {value.dissued_date}</td>
                                                        <td >{value.mstock}</td>
                                                        <td>{value.mmrp_rate.toFixed(2)}</td>
                                                        <td>{value.mmrp_discount}</td>
                                                        <td>{value.mrate.toFixed(2)}</td>
                                                        <td>{value.mtotal_amt.toFixed(2)}</td>
                                                    </tr>)}
                                                <tr>
                                                    <td style={{ textAlign: 'center', fontSize: '16px', fontWeight: '600' }} colSpan={9}>Total</td>
                                                    <td style={{ fontSize: '16px', fontWeight: '600' }}>{totalAmt.toFixed(2)}</td>
                                                </tr>
                                            </tbody> :
                                            <tbody>
                                                <tr>
                                                    <td className='text-center p-5' style={{ fontSize: "18px", color: '#9d9d9d', fontWeight: "500" }} colSpan={10}> DATA NOT FOUND</td>
                                                </tr>
                                            </tbody>}

                            </Table>
                            {/* </PerfectScrollbar> */}
                        </div>
                    </div>
                </div>
            </div>
            <IssuedReportPdf pdfModal={pdfModal} setPdfModal={setPdfModal} startDate={startDate} setStartDate={setStartDate} reportData={reportData} setReportData={setReportData} />
        </>
    )
}
